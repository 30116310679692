
/************HEADER******************/
header {
    background-color: #40514E;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    z-index: 5;
}

/**************** NAVBAR *******************/

.navbar {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    background-color: #40514E;
    padding: 0;
    margin-right: 50px;
    text-transform: uppercase;
    justify-content: flex-start;
}

.navbar-brand {
    font-family: 'Marck Script', cursive;
    font-size: 2.7rem;
    padding-top: 0;
    padding-bottom: 0;
    color: #fff;
    font-weight: 400;
    align-self: center;
    margin-right: auto;
    padding-left: 10px;
    text-transform: none;
}

.navbar-brand:hover {
    color:#fff;
}

.nav-link {
    cursor: pointer;
    color: #fff;
    /* align-self: center; */
}

.nav-link:hover {
    color: #30E3CA;
}

.navbar p {
    margin-bottom: 0;
    margin-left: 8px;
    margin-right: 8px;
}

.navbar-toggler {
    color: white;
    text-transform: uppercase;
    font-size: 1rem;
    background-color: black;
    line-height: 1.5;
}

.navbar-toggler i {
    padding-left: 6px;
}



/***********ABOUT ********************/

#about-section {
    background-color: #E4F9F5;
    padding-top: 1%;
    padding-bottom: 30px;
    height: 1100px;
}

.title {
    background-color: #11999E;
    height: 550px;
    border-radius: 30px;
    z-index: 2; 
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
    background-image: url("../../images/gplay.png");
}

.profile-pic-wrapper {
    height: 500px;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
    
.profile-pic {
    width: auto;
    height: 400px;
    border: solid 3px #ffa43b;
    border-radius: 30px;
    margin-top: 40px;
}

.intro-text {
    font-style: italic;
    font-weight: 400;
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.intro-wrapper {
    margin-left: 50px;
    /* margin-right: 30px; */
    margin-top: 30px;
    margin-bottom: 100px; 
}

.i-name {
    font-size: 5rem;
    margin-bottom: 50px;
    color: #fff;
}

.i-title {
    height: 50px;
    overflow: hidden;
}

.i-title-item {
    height: 50px;
    font-size: 2.2rem;
    font-weight: bold;
    color: #ffa43b;
    background-color: #11999e00;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.i-title-wrapper {
    height: 100%;
    animation: move 17s ease-in-out infinite alternate;
}

@keyframes move {
    25% {
        transform: translateY(-200px);
    }
    50% {
        tranform: translateY(-250px);
    }
    75% {
        tranform: translateY(-300px);
    }
    100% {
        tranform: translateY(-350px);
    }
}

.about-me-text {
    background-color: #fff;
    margin-top: 10px;
    border-radius: 30px;
    display: block;
    text-align: left;
}

.about-me-text p {
    font-size: 1.1rem;
    color: #40514E; 
    font-weight: 400;
}

.about-me-title {
    margin-bottom: 10px;
}

.about-me-title h2 {
    color: #40514E;
    padding-bottom: 10px;
}

@media (max-width: 1100px) {
    #about-section {
        height: 1400px;
    }
    .i-title-item {
        font-size: 1.7rem;
    }
    .title {
        height: 450px;
    }
}

@media (max-width: 900px) {
    #about-section {
        height: 1500px;
    }
    .about-me-text p {
        font-size: 1rem;
    }
    .profile-pic {
        height: 250px;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .title {
        height: 475px;
        padding-top: 20px;
    }
    .intro-text {
        font-size: 2rem;
        padding-top: 20px;
    }
    .i-name{
        font-size: 4rem;
    }

}

@media (max-width: 400px) {
    .i-title-item {
        font-size: 1.5rem;
    }
    .intro-text {
        font-size: 1.7rem;
    }
    .navbar-brand {
        font-size: 2.1rem;
    }
}


