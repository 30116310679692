
/***********PROJECTS ***************/

#project-section {
    background-color: #11999E;
    background-image: url("../../images/project-paper.png");
    padding-top: 1%;
    height: 1700px;
    text-align: center;
}

.row {
    margin-bottom: 0;
    padding-bottom: 0; 
}

.project-section-title {
    margin-bottom: 60px;
    margin-top: -20px;
}

.project-section-title h1 {
    color: #fff;
    font-size: 3.5rem;
    text-transform: uppercase;
}

.project-section-title p {
    color: #fff;
    font-style: italic;
    font-size: 1.1rem;
}

.project-grid {
    display: flex;
    justify-content: center;
} 

/* .bottom */

.project {
    height: auto;
    max-width: 550px;
    align-self: center;
    margin-top: 70px;
} 

.project-info {
    text-align: center;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(218, 218, 218, 0.418);
}

.project-info .btn {
    margin-right: 5px;
}

.tech-text {
    font-size: 0.8rem;
    margin: 15px 0;
    padding: 0 10px;
}

.card-text {
    font-size: 1.1rem;
    margin: 2% 0;
}

.card-img {
    border: 2px solid rgba(107, 107, 107, 0.418);
}

.g-project-btn {
    background-color: #ffa43b;
    color: #fff;
    font-weight: 500;
    width: 125px;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    border-radius: 3px;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    transition: all 0.1s;
    -webkit-box-shadow: 0px 3.5px 0px #ca781a;
    -moz-box-shadow: 0px 3.5px 0px #ca781a;
    box-shadow: 0px 3.5px 0px #ca781a;
}

.g-project-btn:focus {
    color: #fff;
    box-shadow: none;
    outline: none;
    background-color: #ffa43b;
    border: none;
    border-radius: 3px;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    transition: all 0.1s;
    -webkit-box-shadow: 0px 3.5px 0px #ca781a;
    -moz-box-shadow: 0px 3.5px 0px #ca781a;
    box-shadow: 0px 3.5px 0px #ca781a;
}

.g-project-btn:hover {
    background-color: #ec8c1e;
    color: #fff;
}

.g-project-btn:active{
    -webkit-box-shadow: 0px 1px 0px #ca781a;
    -moz-box-shadow: 0px 1px 0px #ca781a;
    box-shadow: 0px 1px 0px #ca781a;
    position:relative;
    top:4px;
}

.modal-dialog {
    display: flex;
    justify-content: center;
}

.modal-content {
    width: 1300px;
}

.e-project-img {
    width: 1000px;
}

@media (max-width: 1300px) {
    .e-project-img {
        width: 100%;
    }
    .modal-content {
        width: 100%;
    }
}

@media (max-width: 1000px) {
    #project-section {
        height: 2200px;
    }
    .project-section-title {
        margin-top: 0;
    }
}

@media (max-width: 400px) {
    .g-project-btn {
        margin-top: 8px;
    }    
}